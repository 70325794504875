import { UpdatesConfig } from "@urql/exchange-graphcache";
import { DocumentNode, OperationDefinitionNode } from "graphql";
import {
  AddProductGroupInHierarchyMutation,
  AddProductGroupInHierarchyMutationVariables,
  AddProductInHierarchyMutation,
  AddProductInHierarchyMutationVariables,
} from "../mutations.generated";
import { GetAllProductGroupsInHierarchyDocument } from "../queries.generated";

export const extractQueryName = (query: DocumentNode) => {
  return (query.definitions[0] as OperationDefinitionNode).name?.value ?? "";
};

const QUERY_KEY = extractQueryName(GetAllProductGroupsInHierarchyDocument);

const addproductHierarchyConfig: Partial<UpdatesConfig>["Mutation"] = {
  // Updater function for creating a new product in the hierarchy
  createHierarchyNextProduct(
    _result: AddProductInHierarchyMutation["createHierarchyNextProduct"],
    _args: { data: AddProductInHierarchyMutationVariables },
    cache,
    _info
  ) {
    cache
      .inspectFields("Query")
      .filter((field) => field.fieldName === QUERY_KEY)
      .forEach((field) => {
        // Invalidate the cache for the query getAllProductGroupsInHierarchy and refetch
        cache.invalidate("Query", field.fieldKey);
      });
  },
};

const addproductGroupHierarchyConfig: Partial<UpdatesConfig>["Mutation"] = {
  // Updater function for creating a new product group in the hierarchy
  createHierarchyNextProductGroup(
    _result: AddProductGroupInHierarchyMutation["createHierarchyNextProductGroup"],
    _args: { data: AddProductGroupInHierarchyMutationVariables },
    cache,
    _info
  ) {
    cache
      .inspectFields("Query")
      .filter((field) => field.fieldName === QUERY_KEY)
      .forEach((field) => {
        // Invalidate the cache for the query getAllProductGroupsInHierarchy and refetch
        cache.invalidate("Query", field.fieldKey);
      });
  },
};

export { addproductHierarchyConfig, addproductGroupHierarchyConfig };
